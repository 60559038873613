export default {
    'original_password': 'Original Password',
    'new_password': 'New Password',
    'enter_the_original_password': 'Enter the original password',
    'enter_the_original_password_login': 'Enter the original password',
    'enter_the_new_password_(enter_8_or_more_characters)': 'Enter the new password (enter 8 or more characters)',
    'confirm_password': 'Confirm Password',
    're_enter_the_new_password': 'Re-enter the new password',
    'confirm_modification': 'Confirm Modification',
    'enter_new_password_enter_6_digit_number': 'Enter new password (enter 6-digit number)',
    'recharge_amount': 'Recharge Amount',
    'reason_for_rejection': 'Reason for Rejection',
    'time': 'time',
    'in_review': 'In Review',
    'completed': 'Completed',
    'rejected': 'Rejected',
    'current_browser_does_not_support_copy': 'Current browser does not support copy',
    'copy_successful': 'Copy Successful',
    'home': 'Home',
    'liquidity_pool': 'Liquidity Pool',
    'me': 'Me',
    'recharge': 'Recharge',
    'withdraw': 'Withdraw',
    'buy_second_contract': 'Buy Second Contract',
    'settle_second_contract': 'Settle Second Contract',
    'buy_lever': 'Buy Lever',
    'settle_lever': 'Settle Lever',
    'stake_lp': 'Stake LP',
    'earnings_lp': 'Earnings LP',
    'settlement_lp': 'Settlement LP',
    'admin': 'Level Rewards',
    'withdraw_amount': 'Withdraw Amount',
    'withdrawal_address': 'Withdrawal Address',
    'rejection_reason': 'Rejection Reason',
    'under_review': 'Under Review',
    'invitation_code': 'Invitation Code',
    'balance': 'Balance',
    'today': 'Today',
    'deposit': 'Deposit',
    'real_name_authentication': 'Real Name Authentication',
    'billing_history': 'Billing History',
    'second_contract_order': 'Second Contract Order',
    'liquidity_pool_order': 'Liquidity Pool Order',
    'my_team': 'My Team',
    'change_password': 'Change Password',
    'logout': 'Logout',
    'cancel': 'Cancel',
    'document_type': 'Document Type',
    'real_name': 'Real Name',
    'please_enter_real_name': 'Please enter real name',
    'please_enter_document_number': 'Please enter document number',
    'document_number': 'Document Number',
    'document_photo': 'Document Photo',
    'front': 'Front',
    'back': 'Back',
    'submit': 'Submit',
    'certification_passed': 'Certification Passed',
    'identity_card': 'Identity Card',
    'passport': 'Passport',
    'login_password': 'Login Password',
    'transaction_password': 'Transaction Password',
    'low': 'Low',
    'high': 'High',
    'open': 'Open',
    'close': 'Close',
    'buy_rise': 'Up',
    'buy_fall': 'Down',
    'return_rate': 'Return Rate',
    'capital': 'Capital',
    'is_leverage': 'Is Leverage',
    'leverage_ratio': 'Leverage Ratio',
    'amount': 'Amount',
    'available_amount': 'Available Amount',
    'expected_return': 'Expected Return',
    'transaction_fee': 'Transaction Fee',
    'confirmation': 'Confirmation',
    'price': 'Price',
    'direction': 'Direction',
    'rise': 'Rise',
    'fall': 'Fall',
    'enter_payment_password': 'Enter Payment Password',
    'in_progress': 'In Progress',
    'final_price': 'Final Price',
    'leverage': 'Leverage',
    'handling_fee': 'Handling Fee',
    'expected_profit_loss': 'Expected Profit/Loss',
    'creation_time': 'Creation Time',
    'no_more': 'No More',
    'has_ended': 'Has Ended',
    'actual_profit_and_loss': 'Actual Profit and Loss',
    'end_time': 'End Time',
    'billing_record': 'Billing Record',
    'currency_pair': 'Currency Pair',
    'latest_price': 'Latest Price',
    'price_change_percentage': 'Price Change Percentage',
    'staking_liquidity_pool': 'Staking',
    'participate_in_staking': 'Staking',
    'current_total_staking': 'Current Total Staking',
    'cumulative_rewards': 'Cumulative Rewards',
    'add_liquidity': 'Add Liquidity',
    'daily_yield_rate': 'Daily Yield Rate',
    'staking_limit': 'Staking Limit',
    'day': 'Day',
    'staking_time': 'Staking Time',
    'input_staking_amount': 'Input Staking Amount',
    'staking_amount': 'Staking Amount',
    'total_return_rate': 'Total Return Rate',
    'confirm': 'Confirm',
    'liquidity_pool_staking_order': 'Liquidity Pool Staking Order',
    'daily_profit': 'Daily Profit',
    'due_date': 'Due Date',
    'order_id': 'Order ID',
    'expiration_time': 'Expiration Time',
    'cancel_order': 'Cancel Order',
    'confirm_cancellation': 'Confirm Cancellation',
    'penalty_rate': 'Penalty Rate',
    'expected_arrival_amount': 'Expected Arrival Amount',
    'login_account': 'Login Account',
    'input_email_account': 'Input Email Account',
    'email': 'Email',
    'password': 'Password',
    'input_login_password': 'Input Login Password',
    'login': 'Login',
    'register_now': 'Register Now',
    'no_account_yet': 'No account yet',
    'account': 'Account',
    'invitation_time': 'Invitation Time',
    'level': 'Level',
    'please_select_withdrawal_currency': 'Withdrawal Currency',
    'withdrawal_amount': 'Withdrawal Amount',
    'please_enter_withdrawal_amount': 'Please enter withdrawal amount',
    'please_enter_withdrawal_address': 'Please enter withdrawal address',
    'please_enter_transaction_password': 'Please enter transaction password',
    'estimated_actual_arrival': 'Estimated Actual Arrival',
    'please_select_recharge_currency': 'Recharge Currency',
    'recharge_address': 'Recharge Address',
    'please_enter_recharge_amount': 'Please enter recharge amount',
    'please_enter_recharge_hash': 'Please enter recharge hash',
    'recharge_hash': 'Recharge Hash',
    'upload_recharge_proof_payment_screenshot': 'Upload Recharge Proof (Payment Screenshot)',
    "please_authenticate_first": "Please authenticate first",
    "create_account": "Create Account",
    "verification_code": "Verification Code",
    "enter_verification_code": "Enter Verification Code",
    "get_verification_code": "Get Verification Code",
    "funds_password": "Funds Password",
    "register": "Register",
    "set_funds_password_6_digit_number": "Set Funds Password (6-digit number)",
    "set_login_password_input_8_plus_characters": "Set Login Password (input 8+ characters)",
    "please_enter_email": "Please enter email",
    "announcement": "Announcement",
    "announcement_details": "Announcement Details",
    "times": "Times",
    "the_purchase_period_has_ended_please_make_a_new_selection": "The purchase period has ended, please make a new selection",

    "index": {
        button: "Start Trading",
        banTip: "Uniswap is a fixed-odds trade on the blockchain, where your win or loss depends on whether the mid-price of the digital currency at the expiration time is higher or lower than the transaction price at the time of purchase.",
        tip1: "Minimum holding time as low as 60s",
        tip2: "Intuitive and easy to use platform experience",
        tip3: "Competitive rate of return",
        tip4: "Diversified product flow pools stabilize returns",

    },
    "newHome": {
        Cryptos: "Cryptos",
        Forex: "Forex",
        Gold: "Gold",
        futures: "futures",

    },
    "newPool": {
        StartStaking: "Start staking"
    },
    "newMe": {
        balance: "Account Balance",
        saveQr: "Save QR Code",
        TopUp: "Top-up Rewards",
    },
    newLevel: "Level",
    Cumulative: "Current cumulative total amount",
    levelSets: "Recharge upgrade",
    kefu: "Contact customer service to receive",
    levelInfo: {
        infoLv1: "Unlock VIP channel for recharge and withdrawal",
        infoLv2: "Unlock monthly VIP welfare gold of 888",
        infoLv3: "Enjoy daily fixed income of 0.001 from the platform wallet balance",
        toUpUsd: "{name} Recharge Target"
    },
    News: "News",

    StayTuned: "Stay tuned",
    develop: "Development in progress, please stay tuned ~ ~",
    "赠送": "Giveaway",
    "大礼包": "Gift Pack",
    "联系客服领取": "Customer Service",


    meCard: "My Bank Card",
    cardAdmin: "Bank Card Management",
    newCard: {
        cardName: "Bank Name",
        cardNamePla: "Please enter bank name",
        number: "Payee Account",
        numberPla: "Please enter payee account number",
        name: "Payee Name",
        namePla: "Please enter payee name",
        save: "Save",
        balance: "Account Balance",
        cardInfos: "Bank Card Information",
        customer_service: "Please contact customer service, withdrawal time: 11:00 - 23:00",
        addCardInfo: "Please add bank card information first"
    },
    status: "Status",
    Order: 'Order',

    banTip2: "Please note that investing is risky.",
    langeSet: "Language Selection",
    Sure: "Sure",
    createAcount: {
        have: "Already have an account",
        goLogin: "Go to login"
    },
    Name: "Name",
    total_revenue: "Total Revenue",
    Return: "Return",
    personal: "Personal Center",
    operate: "Operate",
    information: "Information"







}
